import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent {

  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - Privacy Policy');
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
}
