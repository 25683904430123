import { BrowserModule, Title, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ScullyLibModule } from '@scullyio/ng-lib';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home/home-page/home-page.component';
import { HeatingComponent } from './features/heating/heating.component';
import { AtWorkComponent } from './features/at-work/at-work.component';
import { OnTimeComponent } from './features/on-time/on-time.component';
import { ForCustomersComponent } from './features/for-customers/for-customers.component';
import { PricingPageComponent } from './pricing/pricing-page/pricing-page.component';
import { TermsOfServicePageComponent } from './terms_of_service/terms-of-service-page/terms-of-service-page.component';
import { PrivacyPolicyPageComponent } from './privacy_policy/privacy-policy-page/privacy-policy-page.component';
import { SharedModule } from './shared/shared.module';
import { TabsComponent } from './features/tabs/tabs.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeatingComponent,
    AtWorkComponent,
    OnTimeComponent,
    ForCustomersComponent,
    PricingPageComponent,
    TermsOfServicePageComponent,
    PrivacyPolicyPageComponent,
    TabsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    MatSliderModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    SharedModule,
    ScullyLibModule,
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
