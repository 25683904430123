import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('growShrink', [
      state('grow', style({
        transform: 'scale(1.03)',
      })),
      state('shrink', style({
        transform: 'scale(1)',
      })),
      transition('shrink <=> grow', [
        animate('100ms'),
      ])
    ])
  ]
})
export class HomePageComponent implements OnInit {
  isHeating = true;
  isWork = true;
  isTime = true;
  isCustomers = true;

  constructor(
    private titleService: Title,
    private pageHeaderService: PageHeaderService,
  ) {
    this.setTitle('hOurDAYS - At work for your business');
  }

  ngOnInit(): void {
    this.pageHeaderService.dynamicHeader = true;
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  toggleHeating(): void {
    this.isHeating = !this.isHeating;
  }

  toggleWork(): void {
    this.isWork = !this.isWork;
  }

  toggleTime(): void {
    this.isTime = !this.isTime;
  }

  toggleCustomers(): void {
    this.isCustomers = !this.isCustomers;
  }
}
