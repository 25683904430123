import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  private dynamicHeaderLocal: boolean;
  private dynamicHeaderSubject = new BehaviorSubject<boolean>(false);
  dynamicHeader$: Observable<boolean>;
  handset = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.dynamicHeaderLocal = false;
    this.dynamicHeader$ = this.dynamicHeaderSubject.asObservable();
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Tablet,
    ]);
    layoutChanges.subscribe(result => {
      this.handset = result.matches;
      this.updateDynamicHeader(this.handset ? false : this.dynamicHeader);
    });
  }

  get dynamicHeader(): boolean {
    return this.dynamicHeaderLocal;
  }

  set dynamicHeader(value: boolean) {
    this.dynamicHeaderLocal = this.handset ? false : value;
    this.updateDynamicHeader(this.dynamicHeaderLocal);
  }

  private updateDynamicHeader(value: boolean): void {
    this.dynamicHeaderSubject.next(value);
  }
}
