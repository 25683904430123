import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { cookieConfig } from './cookie-consent/cookie-consent.config';
import { MatButtonModule } from '@angular/material/button';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';

@NgModule({
  declarations: [
    CookieConsentComponent,
    ImageCarouselComponent,
    PageFooterComponent,
    PageHeaderComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    RouterModule,
  ],
  exports: [
    CookieConsentComponent,
    ImageCarouselComponent,
    PageFooterComponent,
    PageHeaderComponent,
  ]
})
export class SharedModule { }
