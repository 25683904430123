import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-at-work',
  templateUrl: './at-work.component.html',
  styleUrls: ['./at-work.component.scss']
})
export class AtWorkComponent {
  carouselImages: string[] = [];

  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - At Work');
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
