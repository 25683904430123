import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { IHeaderActions } from '../models';
import { PageHeaderService } from '../services';

@Component({
  selector: 'hdm-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  animations: [
    trigger('largeSmall', [
      state('large', style({
        height: '180px',
      })),
      state('small', style({
        height: '52px',
      })),
      transition('large => small', [
        animate('300ms ease')
      ]),
      transition('small => large', [
        animate('100ms ease')
      ])
    ]),
    trigger('highlight', [
      state('highlighted', style({
        'background-color': 'white',
        color: '#BB132F',
        opacity: '1'
      })),
      state('normal', style({
        'background-color': '#BB132F',
        color: 'white'
      })),
      transition('normal <=> highlighted', [
        animate('300ms ease')
      ])
    ])
  ]
})
export class PageHeaderComponent implements OnInit {
  headerActions: IHeaderActions[];
  highlight = false;

  dynamicHeader: boolean;
  @Input() largeHeader: boolean;
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter();
  @Output() closeSideNav: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private pageHeaderService: PageHeaderService,
  ) { }

  ngOnInit(): void {
    this.headerActions = this.initHeaderActions();
    this.pageHeaderService.dynamicHeader$.subscribe(value => this.dynamicHeader = value);
  }

  clickMenu(): void {
    this.toggleSideNav.emit(true);
  }

  closeMenu(): void {
    this.closeSideNav.emit(true);
  }

  toggleHighlightAction(action: IHeaderActions): void {
    action.animation = !action.animation;
  }

  toggleHighlight(): void {
    this.highlight = !this.highlight;
  }

  private initHeaderActions(): IHeaderActions[] {
    return [
      {
        name: '...heating',
        route: '/features/heating',
        icon: 'hd:fire',
        animation: false
      },
      {
        name: '...at work',
        route: '/features/at-work',
        icon: 'hd:work',
        animation: false
      },
      {
        name: '...on time',
        route: '/features/on-time',
        icon: 'hd:clock',
        animation: false
      },
      {
        name: '...for customers',
        route: '/features/for-customers',
        icon: 'hd:customers',
        animation: false
      },
      {
        name: 'pricing',
        route: '/pricing',
        icon: 'hd:pound',
        animation: false
      },
    ];
  }
}
