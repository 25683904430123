<mat-toolbar class="grid-container">
    <div class="flex-container">
        <div class="terms-of-service">
            <h4>Terms of Service</h4>
            <a [routerLink]='["/terms-of-service"]'>Terms Of Service</a>
            <a [routerLink]='["/privacy-policy"]'>Privacy Policy</a>
            <a href="http://www.mersenneit.com/" target="_blank">&#169; 2015-{{dateTime | date:'yyyy'}} - Mersenne IT Limited</a>
        </div>

        <div class="customer-service">
            <h4>Customer Service</h4>
            <a href="mailto:support@hourdays.com">Help and Support</a>
        </div>

        <div class="contact-us">
            <h4>Social Media</h4>
            <div class="social-media">
                <a href="https://www.facebook.com/hourdays" target="_blank"><mat-icon id="facebook" svgIcon="hd:facebook"></mat-icon></a>
                <a href="https://twitter.com/askhourdays" target="blank"><mat-icon id="twitter" svgIcon="hd:twitter"></mat-icon></a>
            </div>
        </div>
    </div>
</mat-toolbar>