<div [ngClass]="getRoute(url)">
    <div class="grid-img-heating">
        <img @slideAnimation src="/assets/img/VanHeating.svg" alt="Image of a van with flames on the side" draggable="false">
    </div>

    <mat-card class="grid-tabs-heating" [routerLink]="['/features/heating']">
        <h1>...heating</h1>
    </mat-card>

    <div class="grid-img-work">
        <img @slideAnimation src="/assets/img/VanWork.svg" alt="Image of a van with a spanner on the side" draggable="false">
    </div>

    <mat-card class="grid-tabs-work" [routerLink]="['/features/at-work']">
        <h1>...at work</h1>
    </mat-card>

    <div class="grid-img-time">
        <img @slideAnimation src="/assets/img/VanTime.svg" alt="Image of a van with a clock on the side" draggable="false">
    </div>

    <mat-card class="grid-tabs-time" [routerLink]="['/features/on-time']">
        <h1>...on time</h1>
    </mat-card>

    <div class="grid-img-customers">
        <img @slideAnimation src="/assets/img/VanCustomers.svg" alt="Image of a van with customers on the side" draggable="false">
    </div>

    <mat-card class="grid-tabs-customers" [routerLink]="['/features/for-customers']">
        <h1>...for customers</h1>
    </mat-card>
</div>