import { Component, OnInit, Input } from '@angular/core';
import { trigger, style, transition, animate, keyframes, state } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'hdm-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate(1000, style({transform: 'translateX(0)'}))
      ])
    ])
]})

export class TabsComponent implements OnInit {
  url;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.getUrl();
  }

  getRoute(route: string): string {
    switch (route) {
      case '/features/heating':
        return 'heating';
      case '/features/at-work':
        return 'work';
      case '/features/on-time':
        return 'time';
      case '/features/for-customers':
        return 'customers';
    }
  }

  getUrl(): void {
    this.url = this.router.url;
  }
}
