import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-for-customers',
  templateUrl: './for-customers.component.html',
  styleUrls: ['./for-customers.component.scss']
})
export class ForCustomersComponent {
  carouselImages1: string[] = [
    '/assets/img/FTCustomerRecord.png',
    '/assets/img/FTCustomerUpdates.png',
    '/assets/img/FTCustomerNotes.png',
  ];

  carouselImages2: string[] = [
    '/assets/img/FTCustomerProperty.png',
    '/assets/img/FTCustomerPropertyLandlord1.png',
    '/assets/img/FTCustomerPropertyLandlord2.png',
  ];

  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - For Customers');
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
