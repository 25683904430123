<div class="grid-container">
    <hdm-tabs></hdm-tabs>

    <div class="grid-row1">
        <div class="grid-row1-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Schedule appointments</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Book appointments against all employees</p>
                </div>

                <div class="grid-row-point">
                   <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                   <p>Re-schedule, cancel, and re-assign appointments</p>
              </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Book time out for meetings and employee training</p>
                </div>

                <div class="grid-row-point">
                  <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p>Log staff holidays, absence and illness</p>
              </div>
            </mat-card>

            <div class="image">
                <img src="/assets/img/FTSchedule.png" alt="Desktop screenshot showing calendar and appointment creator wizard">
            </div>
        </div>
    </div>

    <div class="grid-row2">
        <div class="grid-row2-content">
            <mat-card id="secondary" class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2 id="secondary">Send customer notifications</h2>
                </div>

                <div class="grid-row-point">
                  <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p id="secondary">Send appointment confirmations to customers</p>
              </div>

              <div class="grid-row-point">
                <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                <p id="secondary">Remind customers about booked appointments</p>
            </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Notifications are automatically generated and sent out by email</p>
                </div>
            </mat-card>

            <div class="image">
                <img src="/assets/img/FTAppointmentConfirmation.png" alt="Composite image showing appointment confirmation produced by the application">
            </div>
        </div>
    </div>

    <div class="grid-row3">
        <div class="grid-row3-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>The hOurDAYS ...in the field app</h2>
                </div>

                <div class="grid-row-point">
                  <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p>A personal diary</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>View your customer appointments using the app</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>New and re-scheduled appointments sent directly to your mobile phone via the app</p>
                </div>
            </mat-card>

            <div class="image-carousel">
                <hdm-image-carousel class="carousel" [carouselImages]="carouselImages"></hdm-image-carousel>
            </div>
        </div>
    </div>

    <div class="grid-button">
        <a mat-raised-button class="page-button mat-elevation-z5" [routerLink]="['/features/for-customers']">for customers<mat-icon svgIcon="hd:arrow-right"></mat-icon></a>
    </div>
</div>
