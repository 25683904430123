import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-heating',
  templateUrl: './heating.component.html',
  styleUrls: ['./heating.component.scss']
})
export class HeatingComponent {
  carouselImages: string[] = [
    '/assets/img/ServiceReminderEmailLetter.png',
    '/assets/img/MobileWorkApplianceTests.png',
    '/assets/img/MobileWorkInstallationTests.png',
    '/assets/img/MobileWorkCP12.png',
    '/assets/img/GasSafetyCertificate.png',
  ];


  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - Heating');
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
}
