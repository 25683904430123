import { Component, OnInit } from '@angular/core';

import { IFooterActions } from '../models';

@Component({
  selector: 'hdm-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  footerActions: IFooterActions[];
  dateTime = Date.now();

  constructor() { }

  ngOnInit(): void {
    this.footerActions = this.initFooterActions();
  }

  private initFooterActions(): IFooterActions[] {
    return [
      {
        name: 'terms of service',
        route: '/terms-of-service',
      },
      {
        name: 'privacy policy',
        route: '/privacy-policy',

      },
    ];
  }

}
