import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-terms-of-service-page',
  templateUrl: './terms-of-service-page.component.html',
  styleUrls: ['./terms-of-service-page.component.scss']
})
export class TermsOfServicePageComponent {

  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - Terms Of Service');
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

}
