<mat-toolbar class="flex-container mat-elevation-z5" [ngClass]="{'small-menu': !dynamicHeader || !largeHeader}" [@largeSmall]="dynamicHeader && largeHeader ? 'large' : 'small'">
    <div class="flex-container-brand">
        <a class="flex-brand-logo" (click)="closeMenu()" [routerLink]="['/']">
            <img src="/assets/img/hOurDAYSLogoHeating.svg" alt="hOurDAYS logo in a cloud" *ngIf="dynamicHeader && largeHeader" draggable="false">
            <img class="text-logo" src="/assets/img/LogoSM.svg" alt="hOurDAYS text logo" *ngIf="!dynamicHeader || !largeHeader" draggable="false">
        </a>
    </div>
    <div class="flex-container-menu">
        <a [@highlight]="action.animation ? 'highlighted' : 'normal'" (mouseover)="toggleHighlightAction(action)" (mouseout)="toggleHighlightAction(action)" *ngFor="let action of headerActions" mat-flat-button color="primary" class="header-actions"
            [routerLink]="[action.route]"><mat-icon svgIcon="{{action.icon}}"></mat-icon>{{action.name}}</a>

        <a [@highlight]="highlight ? 'highlighted' : 'normal'" (mouseover)="toggleHighlight()" (mouseout)="toggleHighlight()" mat-flat-button color="primary" class="header-actions" href="https://secure.hourdays.com/account/login"
            target="_blank"><mat-icon svgIcon="hd:log-in"></mat-icon>log in</a>
        <div (click)="clickMenu()" class="menu-icon">
            <mat-icon svgIcon="hd:menu"></mat-icon>
        </div>
    </div>
</mat-toolbar>