import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../../../environments/environment';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.production ? 'www.hourdays.com' : 'localhost'
    },
    palette: {
        popup: {
            background: '#D1E1F0'
        },
        button: {
            background: '#287592'
        }
    },
    position: 'top-right',
    theme: 'classic',
    type: 'info',
};
