<div class="grid-container">
    <hdm-tabs></hdm-tabs>

    <div class="grid-row1">
        <div class="grid-row1-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Manage customer records</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Record customer details</p>
                </div>

                <div class="grid-row-point">
                  <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p>Update customer information</p>
              </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Set marketing preferences</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Add notes to customer records</p>
                </div>
            </mat-card>

            <div class="grid-icons">
                <mat-icon class="icon1" svgIcon="hd:customer"></mat-icon>
                <mat-icon class="icon2" svgIcon="hd:property"></mat-icon>
                <mat-icon class="icon3" svgIcon="hd:reminders"></mat-icon>
            </div>
        </div>
    </div>

    <div class="image-carousel">
        <hdm-image-carousel class="carousel" [carouselImages]="carouselImages1"></hdm-image-carousel>
    </div>

    <div class="grid-row2">
        <div class="grid-row2-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Manage customer properties</h2>
                </div>

                <div class="grid-row-point">
                  <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p>Record work performed at a property</p>
                </div>

                <div class="grid-row-point">
                  <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p>Add and remove properties</p>
                </div>

               <div class="grid-row-point">
                 <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                 <p>Add multiple properties per customer</p>
               </div>

               <div class="grid-row-point">
                <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                <p>Great for landlords and letting agents</p>
               </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Mark rental properties and record tenant details</p>
                </div>
            </mat-card>

            <mat-icon class="icon-image-white" svgIcon="hd:properties"></mat-icon>
        </div>
    </div>

    <div class="image-carousel2">
        <hdm-image-carousel class="carousel" [carouselImages]="carouselImages2"></hdm-image-carousel>
    </div>

    <div class="grid-button">
        <a mat-raised-button class="page-button mat-elevation-z5" [routerLink]="['/pricing']">Sign up free for 30 days</a>
    </div>
</div>
