import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router, RouterOutlet, NavigationEnd } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { filter } from 'rxjs/operators';

import { PageHeaderService } from './shared/services';

@Component({
  selector: 'hdm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('void => *', [
        style({opacity: 0}),
        animate(1000, style({opacity: 1}))
      ])
    ])
  ],
})

export class AppComponent implements OnInit {
  largeHeader = true;
  dynamicHeader = false;
  lastScrollPos = 0;
  showCookie = false;
  private readonly LIMIT_POS = 150;
  dateTime = Date.now();

  @Output() closeSideNav: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pageHeaderService: PageHeaderService,
    private router: Router,
    private titleService: Title,
  ) {
    this.matIconRegistry.addSvgIconSetInNamespace('hd',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icon/hourdays.svg')
    );
  }

  ngOnInit(): void {
    this.setTitle('hOurDAYS');
    this.pageHeaderService.dynamicHeader$.subscribe(value => {
      setTimeout(() => this.dynamicHeader = value, 0);
    });
    this.scrollToTopOnRouteChange();
    setTimeout(() => this.showCookie = true, 5000);
  }

  closeMenu(): void {
    this.closeSideNav.emit(true);
  }

  scrollHeader(event: any): void {
    const scrollPos = event.target.scrollTop;
    const scrollDirection = this.getScrollDirection(scrollPos, this.lastScrollPos);
    this.lastScrollPos = scrollPos;

    if (scrollDirection === 'up' && this.largeHeader === false) {
      this.largeHeader = this.checkHeader(scrollPos);
      this.lastScrollPos = 0;
    }

    if (scrollDirection === 'down' && this.largeHeader === true) {
      this.largeHeader = this.checkHeader(scrollPos);
      this.lastScrollPos = 0;
    }
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  private checkHeader(scrollPos: number): boolean {
    if (scrollPos < this.LIMIT_POS) {
      return true;
    }
    return false;
  }

  private getScrollDirection(scrollPos: number, lastScrollPos: number): string {
    if (lastScrollPos < scrollPos) {
      return 'down';
    } else {
      return 'up';
    }
  }

  private scrollToTopOnRouteChange(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        document.querySelector('.mat-sidenav-content').scrollTop = 0;
      });
  }

  prepareRoute(outlet: RouterOutlet): void {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
