<div class="grid-container">
    <div class="grid-content">
        <div class="grid-text">
            <h1>Terms and Conditions</h1>
            <br />
            <p><span class="hd-title-text">hOurDAYS</span> is an online service providing business
                administration software which is accessible by both a website and a mobile application. <span
                    class="hd-text">hOurDAYS</span> is developed and operated by Mersenne IT
                Limited.</p>
            <p>Please read these Terms and Conditions carefully before using the <span class="hd-text">hOurDAYS</span>
                website or mobile application.</p>
            <p>Your access to and use of <span class="hd-text">hOurDAYS</span> is conditioned
                on your acceptance of and compliance with these Terms and Conditions. By accessing or using <span
                    class="hd-text">hOurDAYS</span> you agree to be bound by these Terms and
                Conditions.</p>
    
            <h2>Accounts and Users</h2>
            <p>All Users must be associated with a single Account. An Account represents the business, organisation or
                sole trader and every Account must have a single User who has overall authority for the Account, this
                User is the Account Holder.</p>
            <p>The Account Holder must be 18 years or older to register for an Account. Accounts may not be registered
                or held by "bots" or other automated entities. An Account Holder has the sole authority to add or remove
                Users from the Account and must maintain the security of this Account to prevent unauthorised access.
            </p>
    
            <h2>Payments</h2>
            <p>All Accounts that have been active for more than 30 days must have valid payment details registered
                against the Account. Billing will commence 30 days after the Account was first registered and will
                continue on a monthly cycle.</p>
            <p>The Service is billed in advance on a monthly basis and is non-refundable. All fees are subject to VAT.
            </p>
    
            <h2>Cancellation and Termination</h2>
            <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any
                reason whatsoever, including without limitation if you breach the Terms.</p>
            <p>All provisions of the Terms which by their nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
                liability.</p>
            <p>The Account Holder has the sole right to cancel the Service. Cancellation must be performed through the
                Service by following the cancellation link in the Account section.</p>
            <p>Cancellation of an Account will result in the immediate removal of all access to the Account and the
                deletion of all User data. No further charges will be made against the Account following Cancellation.
            </p>
    
            <h2>Changes to the Service and Prices</h2>
            <p>We reserve the right to make changes to any part of the Service at any time without notice. We also
                reserve the right to cancel or discontinue the Service.</p>
    
            <h2>Third Party Services</h2>
            <p>We make use of third party services for the delivery of some aspects of our service e.g. email delivery,
                postcode lookup and push notifications.</p>
            <p>In order to safeguard you and your data we ensure that our communication with third parties is always
                over a secure connection and we restrict the data that we provide to that necessary to provide the
                service. Where possible any data that is sent is delivered anonymously or by reference to a unique
                identifier known only to us.</p>
            <p>We have no control over, and assume no responsibility for, the content, privacy policies, or practices of
                any third party web sites or services. You further acknowledge and agree that we shall not be
                responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
                or in connection with use of or reliance on any such content, goods or services available on or through
                any such web sites or services.</p>
    
            <h2>Changes to the Terms</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any
                time. If a revision is material we will try to provide at least 30 days notice prior to any new Terms
                and Conditions taking effect. What constitutes a material change will be determined at our sole
                discretion.</p>
    
            <h2>Contact Us</h2>
            <p>If you have any questions about the Terms and Conditions, please contact us at <a
                    href="mailto:enquiries@hourdays.com" class="hd-text">enquiries@hourdays.com</a>.</p>
            <br />
            <p><span class="hd-text">Last updated:</span> 28th September 2015.</p>
        </div>
    </div>
</div>