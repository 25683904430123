<div class="grid-container">
    <div class="grid-pricing">
        <div class="grid-pricing-content">
            <div class="grid-pricing-title">
                <h1>Free for the first month</h1><h2>Cancel anytime</h2>
                <br>
                <h1>Just {{this.unitCost | currency: 'GBP'}} per user per month</h1><h2>No add-ons</h2>
            </div>

            <div class="grid-pricing-signup">
                <a mat-raised-button href="https://secure.hourdays.com/account/signup" target="_blank">Sign up for free</a>
                <h3>No credit card required</h3>
            </div>

            <div class="grid-pricing-logo">
                <img src="/assets/img/PRCloud.svg" alt="Application costs {{this.unitCost | currency: 'GBP'}} per user per month, pricing surrounded by a cloud">
            </div>
        </div>
    </div>

    <div class="grid-scale">
        <div class="grid-scale-content">
            <div class="grid-scale-title">
                <h1>Scalable</h1>
                <h2>Add or remove users at anytime</h2>
            </div>

            <div class="grid-scale-bar">
                <h1>See how much it would cost you</h1>
                <br>
                <mat-slider thumbLabel step="1" value="5" min="0" max="50" (input)="checkSlider($event)"></mat-slider>
            </div>

            <div class="grid-scale-text">
                <h2><span>{{userNum}}</span> users for <span>{{price | currency: 'GBP'}} per day</span></h2>
                <h3>*excluding VAT</h3>
            </div>
        </div>
    </div>

    <div class="grid-faqs">
        <div class="grid-faqs-content">
            <div class="grid-faqs-title">
                <h1>Frequently Asked Questions</h1>
            </div>

            <div class="grid-c1-r1">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How does the subscription work?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>The first month of the subscription is free with no limitations. There are no account setup fees,
                        simply add user data and get started. Beyond the first month there will be a flat cost of {{this.unitCost | currency: 'GBP'}} per
                        user per month. The {{this.unitCost | currency: 'GBP'}} fee covers access to the entire system, including the mobile app and gas
                        safety certificates.
                        User costs for administrators and engineers are the same.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c1-r2">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What happens at the end of the trial?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Activate your plan if hOurDAYS is right for you. If not, there's no need to cancel. Your account
                        becomes inactive, but your data remains intact for 90 days in case you decide to activate your plan
                        later.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c1-r3">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How many users can I have?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>You can add as many users as you like during the trial period. If you decide to continue with
                        hOurDAYS, you will be charged per user per month. New users can be added to the system as you
                        require. Users added to the system in the middle of a month will be pro-rated and you will be billed
                        at your next payment interval.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c1-r4">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How do I cancel?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>In your 'Settings' page, select the 'Subscriptions' tab, and simply click the 'Cancel Subscription'
                        button.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c2-r1">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Do I need a credit card to sign up?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>No. We do not collect credit card information when you sign up for the trial account. We will only
                        collect credit card information when you are ready to activate your plan.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c2-r2">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What forms of payment do you accept?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>We currently accept Visa and Mastercard.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c2-r3">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What kind of commitment am I making?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>hOurDAYS is a pay-as-you-go service, so you are free to cancel your subscription at any time. Account
                        payments will be taken monthly in advance.</p>
                </mat-expansion-panel>
            </div>

            <div class="grid-c2-r4">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            If I have more questions who do I ask?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>Please feel free to contact us at <a href="mailto:enquiries@hourdays.com">enquiries@hourdays.com</a>.
                        We'd be happy to help!</p>
                </mat-expansion-panel>
            </div>
        </div>
    </div>

    <div class="signup">
        <div class="signup-content">
            <h2>Sign up free for 30 days</h2>
            <div class="button">
                <a mat-raised-button href="https://secure.hourdays.com/account/signup" target="_blank">Sign up</a>
            </div>
        </div>
    </div>
</div>
