import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home/home-page/home-page.component';
import { HeatingComponent } from './features/heating/heating.component';
import { AtWorkComponent } from './features/at-work/at-work.component';
import { OnTimeComponent } from './features/on-time/on-time.component';
import { ForCustomersComponent } from './features/for-customers/for-customers.component';
import { PricingPageComponent } from './pricing/pricing-page/pricing-page.component';
import { TermsOfServicePageComponent } from './terms_of_service/terms-of-service-page/terms-of-service-page.component';
import { PrivacyPolicyPageComponent } from './privacy_policy/privacy-policy-page/privacy-policy-page.component';


const routes: Routes = [
  {
    path: '', component: HomePageComponent, data: {animation: 'home'}
  },
  {
    path: 'features/heating', component: HeatingComponent, data: {animation: 'heating'}
  },
  {
    path: 'features/at-work', component: AtWorkComponent, data: {animation: 'at-work'}
  },
  {
    path: 'features/on-time', component: OnTimeComponent, data: {animation: 'on-time'}
  },
  {
    path: 'features/for-customers', component: ForCustomersComponent, data: {animation: 'for-customers'}
  },
  {
    path: 'pricing', component: PricingPageComponent, data: {animation: 'pricing'}
  },
  {
    path: 'terms-of-service', component: TermsOfServicePageComponent, data: {animation: 'terms-of-service'}
  },
  {
    path: 'privacy-policy', component: PrivacyPolicyPageComponent, data: {animation: 'privacy-policy'}
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
