<div class="grid-container">
    <hdm-tabs></hdm-tabs>

    <div class="grid-row1">
        <div class="grid-row1-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Manage customer appliances</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Manage the appliances you install and service</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Add, view and edit appliance details</p>
                </div>
            </mat-card>

            <div class="grid-icons">
                <mat-icon class="icon1" svgIcon="hd:fire"></mat-icon>
                <mat-icon class="icon2" svgIcon="hd:cooker"></mat-icon>
                <mat-icon class="icon3" svgIcon="hd:boiler"></mat-icon>
                <mat-icon class="icon4" svgIcon="hd:water-heater"></mat-icon>
            </div>
        </div>
    </div>


    <div class="grid-row2">
        <div class="grid-row2-content">
            <mat-card id="secondary" class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2 id="secondary">Send service reminders</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Send service reminders by letter or email</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Don't lose that important repeat business</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Follow up via the service reminders dashboard</p>
                </div>
            </mat-card>

            <mat-icon class="icon-image-blue" svgIcon="hd:service-reminders"></mat-icon>
        </div>
    </div>


    <div class="image-carousel">
        <hdm-image-carousel class="carousel" [carouselImages]="carouselImages"></hdm-image-carousel>
    </div>


    <div class="grid-row3">
        <div class="grid-row3-content">
            <mat-card id="secondary" class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2 id="secondary">Issue gas safety certificates</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Use the hOurDAYS ...in the field app</p>
                </div>

                <div class="grid-row-point">
                  <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                  <p id="secondary">Record appliance information at the property</p>
              </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Generate a gas safety certificate from the app</p>
                </div>
            </mat-card>

            <mat-icon class="icon-image-blue" svgIcon="hd:certificates"></mat-icon>
        </div>
    </div>

    <div class="grid-button">
        <a mat-raised-button class="page-button mat-elevation-z5" [routerLink]="['/features/at-work']">
            at work<mat-icon svgIcon="hd:arrow-right"></mat-icon>
        </a>
    </div>
</div>
