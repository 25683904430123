<div class="grid-container">
    <!-- <div class="grid-welcome">
    </div> -->

    <div class="grid-features">
        <div class="grid-features-content">
            <div class="grid-features-title">
                <h1>hOurDAYS ...at work for your heating business</h1>
            </div>

            <div [@growShrink]="isHeating ? 'shrink' : 'grow'" (mouseover)="toggleHeating()" (mouseout)="toggleHeating()" [routerLink]="['/features/heating']" class="grid-background">
                <div class="grid-features1">
                    <div class="grid-features-icon">
                        <mat-icon class="heating" svgIcon="hd:fire"></mat-icon>
                    </div>

                    <div class="grid-features-text">
                        <div class="grid-row-heading">
                            <h2 class="heating">...heating</h2>
                            <!-- <a [routerLink]="['/features/heating']">
                                <mat-icon class="icon-info" svgIcon="hd:info"></mat-icon>
                            </a> -->
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick heating" svgIcon="hd:tick"></mat-icon>
                            <h3>Record customer appliances</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick heating" svgIcon="hd:tick"></mat-icon>
                            <h3>Send service reminders</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick heating" svgIcon="hd:tick"></mat-icon>
                            <h3>Generate gas safety certificates</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div [@growShrink]="isWork ? 'shrink' : 'grow'" (mouseover)="toggleWork()" (mouseout)="toggleWork()" [routerLink]="['/features/at-work']" class="grid-background">
                <div class="grid-features2">
                    <div class="grid-features-icon">
                        <mat-icon class="work" svgIcon="hd:work"></mat-icon>
                    </div>

                    <div class="grid-features-text">
                        <div class="grid-row-heading">
                            <h2 class="work">...at work</h2>
                            <!-- <a [routerLink]="['/features/at-work']">
                                <mat-icon class="icon-info" svgIcon="hd:info"></mat-icon>
                            </a> -->
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick work" svgIcon="hd:tick"></mat-icon>
                            <h3>Store detailed work records</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick work" svgIcon="hd:tick"></mat-icon>
                            <h3>Set prices for work tasks</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick work" svgIcon="hd:tick"></mat-icon>
                            <h3>Access work on the move</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div [@growShrink]="isTime ? 'shrink' : 'grow'" (mouseover)="toggleTime()" (mouseout)="toggleTime()" [routerLink]="['/features/on-time']" class="grid-background">
                <div class="grid-features3">
                    <div class="grid-features-icon">
                        <mat-icon class="time" svgIcon="hd:clock"></mat-icon>
                    </div>

                    <div class="grid-features-text">
                        <div class="grid-row-heading">
                            <h2 class="time">...on time</h2>
                            <!-- <a [routerLink]="['/features/on-time']">
                                <mat-icon class="icon-info" svgIcon="hd:info"></mat-icon>
                            </a> -->
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick time" svgIcon="hd:tick"></mat-icon>
                            <h3>Schedule appointments</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick time" svgIcon="hd:tick"></mat-icon>
                            <h3>Book meetings and training</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick time" svgIcon="hd:tick"></mat-icon>
                            <h3>View a personal diary in the field</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div [@growShrink]="isCustomers ? 'shrink' : 'grow'" (mouseover)="toggleCustomers()" (mouseout)="toggleCustomers()" [routerLink]="['/features/for-customers']" class="grid-background">
                <div class="grid-features4">
                    <div class="grid-features-icon">
                        <mat-icon class="customers" svgIcon="hd:customers"></mat-icon>
                    </div>

                    <div class="grid-features-text">
                        <div class="grid-row-heading">
                            <h2 class="customers">...for customers</h2>
                            <!-- <a [routerLink]="['/features/for-customers']">
                                <mat-icon class="icon-info" svgIcon="hd:info"></mat-icon>
                            </a> -->
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick customers" svgIcon="hd:tick"></mat-icon>
                            <h3>Store customer information</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick customers" svgIcon="hd:tick"></mat-icon>
                            <h3>Contact customers by letter or email</h3>
                        </div>

                        <div class="grid-row-point">
                            <mat-icon class="icon-tick customers" svgIcon="hd:tick"></mat-icon>
                            <h3>Multi-property management for landlords</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="grid-mobile">
        <div class="grid-mobile-content">
            <div class="grid-mobile-image">
                <img src="/assets/img/MobileApp.png" alt="Two mobile app screenshots showing the log in page and an appointment" draggable="false">
            </div>

            <div class="grid-mobile-info">
                <div class="grid-mobile-text">
                    <h2>Available on all devices</h2>
                    <p>Access your work, schedule and customer records on the road</p>
                </div>

                <div class="grid-mobile-download">
                    <a class="apple" href="https://itunes.apple.com/gb/app/hourdays-in-the-field/id1073085774?mt=8"
                        target="_blank"><img src="/assets/img/Badge_Apple.svg" alt="Download on the App Store" draggable="false"></a>
                    <a class="google"
                        href='https://play.google.com/store/apps/details?id=mersenneit.hourdays.ionic&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                        target="_blank"><img alt='Get it on Google Play'
                            src='https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png' draggable="false"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="grid-tour">
        <div class="grid-tour-content">
            <div class="grid-tour-text">
                <h2>Secure and reliable</h2>
                <br>
                <div class="grid-row-point">
                    <mat-icon class="icon-padlock" svgIcon="hd:padlock"></mat-icon>
                    <p>hOurDAYS uses the Microsoft Azure Cloud Service for state of the art server security</p>
                </div>
                <br>
                <br>
                <div class="grid-row-point">
                    <mat-icon class="icon-padlock" svgIcon="hd:padlock"></mat-icon>
                    <p>Microsoft's Azure Cloud Service allows hOurDAYS to be operational 24/7 and crucially out in the field</p>
                </div>
                <br>
                <br>
                <div class="grid-row-point">
                    <mat-icon class="icon-padlock" svgIcon="hd:padlock"></mat-icon>
                    <p>hOurDAYS is a UK based service with its infrastructure hosted across multiple UK data centres</p>
                </div>
            </div>

            <div class="grid-tour-icon">
                <mat-icon svgIcon="hd:padlock"></mat-icon>
            </div>
        </div>
    </div>

    <div class="grid-tour-button">
        <div class="tour-button">
            <a class="page-button button1" mat-raised-button [routerLink]="['/features/heating']">Take the
                tour<mat-icon svgIcon="hd:arrow-right"></mat-icon></a>

            <a class="page-button button2" mat-raised-button [routerLink]="['/pricing']">Sign up free for 30 days<mat-icon svgIcon="hd:pound"></mat-icon></a>
        </div>
    </div>
</div>
