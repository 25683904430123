<div class="grid-container">
    <div class="grid-content">
        <div class="grid-text">
            <h1>Privacy Policy</h1>

            <h2>Overview</h2>
            <p>This Privacy Policy should be read in conjunction with the Terms and Conditions.</p>

            <h2>Information Collection and Use</h2>
            <p>While using <span class="hd-text">hOurDAYS</span> you will provide us with information about your
                business,
                your users and your customers. This information will be securely stored on our servers and access to
                this
                information will be limited to Users associated with your Account. Control over Users of your Account
                and
                responsbility for these Users lies with the Account Holder.</p>
            <p>Customer data is collected in order to provide the services of <span class="hd-text">hOurDAYS</span>
                including but not limited to Appointment Schedules, Work Details and Service Reminders.</p>

            <h2>Log Data</h2>
            <p>We collect information that your browser sends whenever you access and use <span
                    class="hd-text">hOurDAYS</span>. This Log Data may include information such as your computer's
                Internet
                Protocol ("IP") address, browser type, browser version, the areas of <span
                    class="hd-text">hOurDAYS</span>
                that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                This
                information enables us to investigate issues and allows us to plan and monitor the service we provide.
            </p>

            <h2>Cookies</h2>
            <p>Cookies are files with small amounts of data, which are stored by your browser when accessing a web site.
                We
                make some use of Cookies to retain information that helps us to securely identify you when you are using
                <span class="hd-text">hOurDAYS</span>. We may at times use cookies to support particular functionality
                within <span class="hd-text">hOurDAYS</span>. Cookies are essential to the secure operation of <span
                    class="hd-text">hOurDAYS</span> and must be enabled in order to gain access. Any data stored in
                Cookies
                will be kept to a minimum.</p>

            <h2>Security</h2>
            <p>The security of your data is important to us, but remember that no method of transmission over the
                Internet,
                or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to
                protect your data, we cannot guarantee its absolute security.</p>
            <p>It is essential that you safeguard the security of your data by keeping passwords private, changing them
                regularly and removing access from Users who are no longer authorised to use the system.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>Mersenne IT Limited may update this Privacy Policy from time to time. We will notify you of any changes
                by
                posting the new Privacy Policy on the <span class="hd-text">hOurDAYS</span> website. You are advised to
                review this Privacy Policy periodically for any changes.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a
                    href="mailto:enquiries@hourdays.com" class="hd-text">enquiries@hourdays.com</a>.</p>
            <br />
            <p><span class="hd-text">Last updated:</span> 28th September 2015.</p>
        </div>
    </div>
</div>