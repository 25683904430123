<div class="grid-container">
    <hdm-tabs></hdm-tabs>

    <div class="grid-row1">
        <div class="grid-row1-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Manage customer work</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Record new work for customers</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Store detailed records of the work you perform</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Refer back to previous work undertaken</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Easily search your work records and quotes</p>
                </div>
            </mat-card>

            <div class="image">
                <img src="/assets/img/FTWorkPricing2.png" alt="Screenshot of work pricing wizard embedded on a laptop screen">
            </div>
        </div>
    </div>

    <div class="grid-row2">
        <div class="grid-row2-content">
            <mat-card id="secondary" class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2 id="secondary">Quote customers for work</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Book a quote appointment</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Record details of work proposals</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon id="secondary" class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p id="secondary">Track the progress of quoted work</p>
                </div>
            </mat-card>

            <mat-icon class="icon-image-blue" svgIcon="hd:quotes"></mat-icon>
        </div>
    </div>

    <div class="grid-row3">
        <div class="grid-row3-content">
            <mat-card class="grid-row-text mat-elevation-z5">
                <div class="grid-row-header">
                    <h2>Manage service plans</h2>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Create your service plans</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Include a plan name and payment time period</p>
                </div>

                <div class="grid-row-point">
                    <mat-icon class="icon-tick" svgIcon="hd:tick"></mat-icon>
                    <p>Add a service plan against a customer record</p>
                </div>
            </mat-card>

            <mat-icon class="icon-image-white" svgIcon="hd:customer-plan"></mat-icon>
        </div>
    </div>

    <div class="grid-button">
        <a mat-raised-button class="page-button mat-elevation-z5" [routerLink]="['/features/on-time']">
            on time<mat-icon svgIcon="hd:arrow-right"></mat-icon>
        </a>
    </div>
</div>
