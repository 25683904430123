<div class="image-carousel">
    <div class="carousel" (swipeleft)="plusDivs(1)" (swiperight)="plusDivs(-1)" (swipeleft)="stopRotation()" (swiperight)="stopRotation()">
        <img [@fade]="fade ? 'fadeOut': 'fadeIn'" [src]="currentImage" alt="Image in an image carousel" draggable="false">

        <button mat-mini-fab color="default" class="nav-left" (click)="plusDivs(-1)" (click)="stopRotation()">
            <mat-icon svgIcon="hd:arrow-left"></mat-icon>
        </button>
    
        <button mat-mini-fab color="default" class="nav-right" (click)="plusDivs(1)" (click)="stopRotation()">
            <mat-icon svgIcon="hd:arrow-right"></mat-icon>
        </button>
    </div>

    <div class="img-carousel-nav">
        <div class="nav-dots">
            <button mat-mini-fab *ngFor="let image of carouselImages; index as i" [ngClass]="{'dot-selected': slideIndex === i}" class="nav-dot" (click)="currentDiv(i)" (click)="stopRotation()"></button>
        </div>
    </div>
</div>