<div class="grid-container">
    <header class="grid-header">
        <hdm-page-header (closeSideNav)="sideNav.close()" (toggleSideNav)="sideNav.toggle()" [largeHeader]=largeHeader>
        </hdm-page-header>
    </header>

    <mat-sidenav-container>
        <mat-sidenav #sideNav mode="over" [fixedInViewport]="true" [fixedTopGap]="52" position="end">
            <mat-nav-list (click)="sideNav.close()">
                <a mat-list-item href="https://secure.hourdays.com/account/login" target="_blank">
                    <mat-icon svgIcon="hd:log-in"></mat-icon>log in
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/features/heating']">
                    <mat-icon svgIcon="hd:fire"></mat-icon>...heating
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/features/at-work']">
                    <mat-icon svgIcon="hd:work"></mat-icon>...at work
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/features/on-time']">
                    <mat-icon svgIcon="hd:clock"></mat-icon>...on time
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/features/for-customers']">
                    <mat-icon svgIcon="hd:customers"></mat-icon>...for customers
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/pricing']">
                    <mat-icon svgIcon="hd:pound"></mat-icon>pricing
                </a>
                <br>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/terms-of-service']">
                    <mat-icon svgIcon="hd:notes"></mat-icon>Terms of Service
                </a>
                <a mat-list-item (click)="closeMenu()" [routerLink]="['/privacy-policy']">
                    <mat-icon svgIcon="hd:notes-2"></mat-icon>Privacy Policy
                </a>
                <br>
                <a mat-list-item (click)="closeMenu()" href="mailto:support@hourdays.com">
                    <mat-icon svgIcon="hd:help"></mat-icon>Help and Support
                </a>
                <br>
                <a mat-list-item (click)="closeMenu()" href="https://www.facebook.com/hourdays" target="_blank"><mat-icon id="facebook" svgIcon="hd:facebook"></mat-icon>Facebook</a>
                <a mat-list-item (click)="closeMenu()" href="https://twitter.com/askhourdays" target="_blank"><mat-icon id="twitter" svgIcon="hd:twitter"></mat-icon>Twitter</a>
                <br>
                <a mat-list-item (click)="closeMenu()" href="http://www.mersenneit.com/" target="_blank">&#169;
                    2015-{{dateTime | date:'yyyy'}} - Mersenne IT Limited</a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content (scroll)="scrollHeader($event)">
            <main [@routeAnimations]="prepareRoute(outlet)" class="grid-main"
                [ngClass]="{'dynamic-header': dynamicHeader}">
                <router-outlet #outlet="outlet" class="grid-content"></router-outlet>

                <footer class="grid-footer">
                    <hdm-page-footer></hdm-page-footer>
                </footer>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<hdm-cookie-consent *ngIf="showCookie"></hdm-cookie-consent>