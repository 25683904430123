import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatSliderChange } from '@angular/material/slider';

import { PageHeaderService } from '../../shared/services';

@Component({
  selector: 'hdm-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent {
  userNum = 5;
  unitCost = 10;
  price = this.userNum * (this.unitCost / 30);

  constructor(
    private pageHeaderService: PageHeaderService,
    private titleService: Title,
  ) {
    this.pageHeaderService.dynamicHeader = false;
    this.setTitle('hOurDAYS - Pricing');
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  public checkSlider(event: MatSliderChange): any {
    this.userNum = event.value;

    this.price = this.userNum * (this.unitCost / 30);
  }
}
