import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'hdm-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeIn', style({
        opacity: '1',
      })),
      state('fadeOut', style({
        opacity: '0',
      })),
      transition('fadeOut => fadeIn', [
        animate('250ms'),
      ]),
      transition('fadeIn => fadeOut', [
        animate('250ms'),
      ]),
    ])
  ]
})
export class ImageCarouselComponent implements OnInit {
  @Input() carouselImages: string[];

  slideIndex = 0;
  currentImage: string;
  rotation;
  fade = false;

  constructor() {}

  ngOnInit(): void {
    this.currentImage = this.carouselImages[this.slideIndex];
    this.rotation = setInterval(() => this.plusDivs(1), 10000);
  }

  plusDivs(n: number): void {
    this.slideIndex += n;

    if (this.slideIndex > this.carouselImages.length - 1) {
      this.slideIndex = 0;
    } else if (this.slideIndex < 0) {
      this.slideIndex = this.carouselImages.length - 1;
    }

    this.animate();
  }

  currentDiv(n: number): void {
    this.slideIndex = n;

    this.animate();
  }

  fadeOutMethod(): void {
    this.fade = true;
  }

  fadeInMethod(): void {
    this.fade = false;
    this.currentImage = this.carouselImages[this.slideIndex];
  }

  animate(): void {
    this.fadeOutMethod();
    setTimeout(() => this.fadeInMethod(), 250);
  }

  stopRotation(): void {
    clearInterval(this.rotation);
  }
}
